import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import './FellowshipGroupHeader.css'

export default () => {
  const data = useStaticQuery(graphql`
    query fellowshipGroupQuery {
      file(relativePath: {eq: "fellowshipgroup.jpg"}) {
        childImageSharp {
          fluid (maxWidth: 2000, quality: 100){
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
    `)
  return (
    <div>
      <BackgroundImage fluid={data.file.childImageSharp.fluid} className='header_background'>
        <div className='header_title'>
          <h1>小 組 事 工</h1>
        </div>
        <div className='fellowship_words'>
          <p>你們若有彼此相愛的心，眾人因此就認出你們是我的門徒了。<br></br>約13：35</p>
          {/* 我赐给你们一条新命令，乃是叫你们彼此相爱，我怎样爱你们，你们也要怎样相爱。 */}
        </div>
        {/* <div className='header_title_Mobile'>
                    <h1>小 组 事 工</h1>
                    <p>我赐给你们一条新命令，乃是叫你们彼此相爱，我怎样爱你们，你们也要怎样相爱。你们若有彼此相爱的心，众人因此就认出你们是我的门徒了。约13：34-35</p>
                </div> */}
      </BackgroundImage>
    </div>
  )
}