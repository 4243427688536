import React from 'react'
import Navbar from '../components/NavBar/NavBar'
import GlobalFooter from '../components/Footer/GlobalFooter'
import FellowshipGroupHeader from '../components/FellowshipGroup/FellowshipGroupHeader'
import FellowshipGroupContent from '../components/FellowshipGroup/FellowshipGroupContent'

export default () => {
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div>
        <FellowshipGroupHeader />
      </div>
      <div>
        <FellowshipGroupContent />
      </div>
      <div>
        <GlobalFooter />
      </div>
    </div>
  )
}