import React from 'react'
import { Card, Col, Row } from 'antd'
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import './FellowshipGroupContent.css'

export default () => {
    const data = useStaticQuery(graphql`
    fragment fellowshipGroupImage on File {
        childImageSharp {
            fluid(maxWidth:800,maxHeight:600,quality:70,cropFocus:CENTER,fit:COVER) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
            }
        }
    }
    query fellowshipGroupImageQuery {
        pic4: file(relativePath: { eq: "homepage_pic4.jpg" }) {
            ...fellowshipGroupImage
        }
        pic1: file(relativePath: { eq: "homepage_pic1.jpg" }) {
            ...fellowshipGroupImage
        }
        pic2: file(relativePath: { eq: "homepage_pic2.jpg" }) {
            ...fellowshipGroupImage
        }
        pic3: file(relativePath: { eq: "FellowshipGroup_images/FellowshipGroup.jpeg" }) {
            ...fellowshipGroupImage
        }
    }
    `);
    console.log(data);
    const { Meta } = Card;
    const col_width_for_desktop = 6;
    const gutter_size_for_desktop = [24, 24];
    const getTitle = () => { return { "font-weight": "bold", "font-size": "large" }; }
    return (
        <div>
            <div className='content_title'>
                <h1>中華福音教會小組聚會</h1>
            </div>
            <div className='desktop_display'>
                <Row justify='center' gutter={gutter_size_for_desktop}>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic4.childImageSharp.fluid} />} title="教會禱告聚會" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週三、日	晚7-8點</p>
                            <p>地點：教會</p>
                            <p>734-464-7077</p>
                        </Card>
                    </Col>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic1.childImageSharp.fluid} />} title="高中青少年生命小组" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週六	早 10:30-12:00</p>
                            <p>Zoom</p>
                            <p>219-242-5090</p>
                        </Card>
                    </Col>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic1.childImageSharp.fluid} />} title="初中青少年生命小组" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週五	晚 7:30-9:00</p>
                            <p>Zoom</p>
                            <p>219-242-5090</p>
                        </Card>
                    </Col>
                </Row>
                <Row justify='center' gutter={gutter_size_for_desktop}>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic2.childImageSharp.fluid} />} title="Livonia姐妹會" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週二	早 十點</p>
                            <p>吳秀紅姊妹</p>
                            <p>734-432-6032</p>
                        </Card>
                    </Col>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic2.childImageSharp.fluid} />} title="Canton姐妹會" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週二 早 10-11:30</p>
                            <p>罗靖姐妹</p>
                            <p>309-222-7057</p>
                        </Card>
                    </Col>
                </Row>
                <Row justify='center' gutter={gutter_size_for_desktop}>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="FrmHls(1)" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週五	晚 七點</p>
                            <p>陳立，王萍</p>
                            <p>248-890-2388</p>
                        </Card>
                    </Col>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Novi慕溪青年組" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週四	晚 七點半</p>
                            <p>方圓，林純純</p>
                            <p>315-751-5528</p>
                        </Card>
                    </Col>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Novi(1)" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週五	晚 七點半</p>
                            <p>黃毅，何力</p>
                            <p>248-348-0858</p>
                        </Card>
                    </Col>
                </Row>
                <Row justify='center' gutter={gutter_size_for_desktop}>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Novi(2)" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週六	晚 七點半</p>
                            <p>朱兆明，劉瑋</p>
                            <p>248-721-8284</p>
                        </Card>
                    </Col>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Novi(3)" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週五	晚 七點半</p>
                            <p>浦鴻彬，付向群</p>
                            <p>248-863-7376</p>
                        </Card>
                    </Col>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Novi(4)" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週五	晚 七點半</p>
                            <p>牛強，王玉平</p>
                            <p>248-982-4167</p>
                        </Card>
                    </Col>
                </Row>
                <Row justify='center' gutter={gutter_size_for_desktop}>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Novi(5)" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週五	晚 七點半</p>
                            <p>林華，張珏</p>
                            <p>248-881-6213</p>
                        </Card>
                    </Col>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Novi(6)" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週五	晚 七點半</p>
                            <p>王國平，楊潔</p>
                            <p>248-449-8719</p>
                        </Card>
                    </Col>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Novi(7)" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週五	晚 七點半</p>
                            <p>傅源輝，趙茜</p>
                            <p>734-680-0281</p>
                        </Card>
                    </Col>
                </Row>
                <Row justify='center' gutter={gutter_size_for_desktop}>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="家人聚會" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週四	早10-1:30 pm</p>
                            <p>何健雄，童玉菊</p>
                            <p>248-305-7102</p>
                        </Card>
                    </Col>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Northville(1)" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週五	晚 七點半</p>
                            <p>梁伟锋、巩林玉</p>
                            <p>248-686-8123</p>
                        </Card>
                    </Col>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Northville(2)" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週五	晚 七點半</p>
                            <p>張靈惠，許英英</p>
                            <p>248-832-9729</p>
                        </Card>
                    </Col>
                </Row>
                <Row justify='center' gutter={gutter_size_for_desktop}>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Livonia" bordered={false} className='card' headStyle={getTitle()}>
                            <p>隔週五 晚 七點半</p>
                            <p>劉志新，範萌</p>
                            <p>614-288-5608</p>
                        </Card>
                    </Col>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Canton" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週六	晚 八點</p>
                            <p>沈潤南，楊麗敏</p>
                            <p>313-673-5444</p>
                        </Card>
                    </Col>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="H2O青年家庭" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週六	早 十點</p>
                            <p>張洋，陳耀暉</p>
                            <p>734-837-9363</p>
                        </Card>
                    </Col>
                </Row>
                <Row justify='center' gutter={gutter_size_for_desktop}>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Westland" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週五	上午9-11</p>
                            <p>陳效增</p>
                            <p>734-837-2077</p>
                        </Card>
                    </Col>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="UMD大學生" bordered={false} className='card' headStyle={getTitle()}>
                            <p>週五	晚 七點半</p>
                            <p>李耀年</p>
                            <p>248-912-4848</p>
                        </Card>
                    </Col>
                    <Col span={col_width_for_desktop}>
                        <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="English Bible Study" bordered={false} className='card' headStyle={getTitle()}>
                            <p>Saturday	10:00 AM</p>
                            <p>Jerome Tzau</p>
                            <p>248-567-9005</p>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div className='mobile_display'>
                <Card hoverable={false} cover={<Img fluid={data.pic4.childImageSharp.fluid} />} title="教會禱告聚會" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週三、日	晚7-8點</p>
                    <p>地點：教會</p>
                    <p>734-464-7077</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic1.childImageSharp.fluid} />} title="高中青少年生命小组" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週六	早 10:30-12:00</p>
                    <p>Zoom</p>
                    <p>219-242-5090</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic1.childImageSharp.fluid} />} title="初中青少年生命小组" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週五	晚 7:30-9:00</p>
                    <p>Zoom</p>
                    <p>219-242-5090</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic2.childImageSharp.fluid} />} title="Livonia姐妹會" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週二	早 十點</p>
                    <p>吳秀紅姊妹</p>
                    <p>734-432-6032</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic2.childImageSharp.fluid} />} title="Canton姐妹會" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週二 早 10-11:30</p>
                    <p>羅靖姊妹</p>
                    <p>309-222-7057</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="FrmHls(1)" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週五	晚 七點</p>
                    <p>陳立，王萍</p>
                    <p>248-890-2388</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Novi慕溪青年組" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週四	晚 七點半</p>
                    <p>方圓，林純純</p>
                    <p>315-751-5528</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Novi(1)" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週五	晚 七點半</p>
                    <p>黃毅，何力</p>
                    <p>248-348-0858</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Novi(2)" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週六	晚 七點半</p>
                    <p>朱兆明，劉瑋</p>
                    <p>248-721-8284</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Novi(3)" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週五	晚 七點半</p>
                    <p>浦鴻彬，付向群</p>
                    <p>248-863-7376</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Novi(4)" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週五	晚 七點半</p>
                    <p>牛強，王玉平</p>
                    <p>248-982-4167</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Novi(5)" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週五	晚 七點半</p>
                    <p>林華，張珏</p>
                    <p>248-881-6213</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Novi(6)" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週五	晚 七點半</p>
                    <p>王國平，楊潔</p>
                    <p>248-449-8719</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Novi(7)" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週五	晚 七點半</p>
                    <p>傅源輝，趙茜</p>
                    <p>734-680-0281</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="家人聚會" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週四	早10-1:30 pm</p>
                    <p>何健雄，童玉菊</p>
                    <p>248-305-7102</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Northville(1)" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週五	晚 七點半</p>
                    <p>梁伟锋、巩林玉</p>
                    <p>248-686-8123</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Northville(2)" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週五	晚 七點半</p>
                    <p>張靈惠，許英英</p>
                    <p>248-832-9729</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Livonia" bordered={false} className='card' headStyle={getTitle()}>
                    <p>隔週五 晚 七點半</p>
                    <p>劉志新，範萌</p>
                    <p>614-288-5608</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Canton" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週六	晚 八點</p>
                    <p>沈潤南，楊麗敏</p>
                    <p>313-673-5444</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="H2O青年家庭" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週六	早 十點</p>
                    <p>張洋，陳耀暉</p>
                    <p>734-837-9363</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="Westland" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週五	上午9-11</p>
                    <p>陳效增</p>
                    <p>734-837-2077</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="UMD大學生" bordered={false} className='card' headStyle={getTitle()}>
                    <p>週五	晚 七點半</p>
                    <p>李耀年</p>
                    <p>248-912-4848</p>
                </Card>
                <Card hoverable={false} cover={<Img fluid={data.pic3.childImageSharp.fluid} />} title="English Bible Study" bordered={false} className='card' headStyle={getTitle()}>
                    <p>Saturday	10:00 AM</p>
                    <p>Jerome Tzau</p>
                    <p>248-567-9005</p>
                </Card>
            </div>
        </div>
    )
}